  .enterInput{
    text-align: center;
  }

  input[type=text], select {
    width: 10%;
    font-weight:bolder;
    font-size: large;
    text-align: center;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 600px) {
    h3{
      font-size: smaller;
    }
    input[type=text], select {
      width: 50%;
    }
    button[type=submit] {
      width: 50%;
    }

  }

  button[type=submit] {
    width: 10%;
    font-weight:bolder;
    font-size: medium;
    background-color: #76D7C4;
    color: white;
    padding: 14px 20px;
    text-decoration: solid;
    margin: 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }