    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }
      
      body {
        background: #fefefe;
        font-family: sans-serif;
      }
      
      .container {
        width: 90%;
        margin: 50px auto;
      }
      .heading {
        text-align: center;
        font-size: 30px;
        margin-bottom: 50px;
      }
      
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-flow: wrap;
      }
      p{
        margin: 1em;
        font-size: medium;
      }
      
      .box {
        width: 18%;
        background: #fff;
        border: 1px solid #ccc;
        margin-bottom: 50px;
        transition: 0.3s;
      }
      
      .box-header {
        text-align: center;
        padding: 10px 10px;
        color: firebrick;
      }
      
      .box-body {
        text-align: center;
        padding: 10px 10px;
        background: #f3f3f3;
      }
      
      .box-body .btn {
        display: block;
        color: #fff;
        text-align: center;
        background: rgb(113, 109, 109);
        margin-top: 30px;
        text-decoration: none;
        padding: 10px 5px;
      }
      
      .box:hover {
        transform: scale(1.05);
        box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
      }
      
      @media screen and (max-width: 1000px) {
        .box {
          width: 40%;
        }
      }
      
      @media screen and (max-width: 620px) {
        .container {
          width: 100%;
        }
      
        .heading {
          padding: 20px;
          font-size: 20px;
        }
      
        .box {
          width: 80%;
        }
      }
      